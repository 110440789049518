import gql from "graphql-tag";
import { Exam, ExamEdgesResponse, ExamSearchInput } from "../gen/types";
import { ResponseGql } from "../types/MDOTypes";
import { BaseService } from "./BaseService";

export class ExamService extends BaseService {

  constructor() {
    super();
  }

  getAll(examSearch: ExamSearchInput): ResponseGql<ExamEdgesResponse> {
    const query = gql`query exams($examSearch: ExamSearchInput) {
      exams(params: $examSearch) {
        edges {
          id
          type
          createdAt {
            transform(format: "DD/MM/yyyy HH:mm")
          }
          agent {
            name
          }
          campaign {
            name
          }
          client {
            name
            whatsapp
          }
          status
          voucher {
            id
            name
            usedAt {
              transform(format: "DD/MM/yyyy")
            }
          }
          resultData {
            overall
          }
          info12Med {
            statusText
          }
        }
        linkDownloadXLS
        linkDownloadCSV
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { examSearch: examSearch }})
    .then(result => result.data.exams);
  }

  detail(id: string): ResponseGql<Exam> {
    const query = gql`query details($id: ID!) {
      exam(id: $id) {
        id
        agent {
          name
        }
        campaign {
          name
        }
        client {
          name
          whatsapp
          acceptedReceiveMessage
          accepted12med
          camera
        }
        account {
          name
        }
        type
        from
        status
        createdAt {
          transform(format: "DD/MM/yyyy")
        }
        updatedAt {
          transform(format: "DD/MM/yyyy")
        }
        voucher {
          name
          code
          discountLabel
          usedAt {
            transform(format: "DD/MM/yyyy")
          }
        }
        info12Med {
          statusText
          data {
            statusText
            agent {
              name
              crm
            }
            date {
              transform(format: "DD/MM")
            }
          }
        }
        resultImage
      }
    }`;

    return this.apolloClient.query({ query: query, variables: { id: id }})
    .then(result => result.data.exam);
  }

}
